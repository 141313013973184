import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { editBalance } from '../../../../../helpers/BalanceHelper';
import ConfirmDialog from '../../../../common/dialogs/confirm';
import { setShowingDialog } from '../../../../../store/app';
import { ClientPaymentTransactionsModal } from '../clientPaymentTransactionsModal/ClientPaymentTransactionsModal';
import { useClientPaymentTransactionsTable } from './useClientPaymentTransactionsTable';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { ClientPaymentTransactionsTableTop } from './elems/clientPaymentTransactionTableTop';
import { DownloadInvoiceModal } from '../../../../common/dialogs/downloadInvoiceModal/DownloadInvoiceModal';
import { ClientPaymentTransactionsTableFooter } from './elems/clientPaymentTransactionTableFooter';
import { CardHeader, Divider, Pagination } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { STATUS_TRANSACTION_COLORS } from '../../../../../constants/statusTransactionColor';
import { useAccessRole } from '../../../../../hooks/useAccessRole';
import { CustomCell } from '../../../../common/tables/CustomCell';
import { dateFormat } from '../../../../../helpers/DateHelper';

const TableHeadings = [
  '№',
  'Фактическая дата оплаты',
  'Дата создания',
  'Сумма',
  'Тип аренды',
  'Период с',
  'по',
  'Комментарий',
  'Действия',
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const ClientPaymentTransactionsTable = () => {
  const {
    transactionList,
    setTransactionId,
    methods,
    dispatch,
    lastPage,
    page,
    matches,
    changePage,
    handleEditCommentPaymentInvoice,
    handleSendMailPaymentInvoice,
    handleDeletePaymentInvoice,
    handleAcceptPaymentInvoice,
  } = useClientPaymentTransactionsTable();

  const role = useAccessRole();

  if (!transactionList.length) {
    return (
      <Card
        className='Overflow-box-each'
        sx={{ overflowX: 'auto' }}
        raised
      >
        <CardHeader title='Платежные операции и документы не найдены' />
      </Card>
    );
  }

  return (
    <>
      <Card
        className='Overflow-box-each'
        sx={{ overflowX: 'auto', my: 2, mb: 0, p: 2 }}
        raised
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ fontSize: 20, fontWeight: 'bold' }}>
            Платежные операции и документы
          </div>

          {lastPage > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination
                siblingCount={!matches ? 0 : 1}
                size={!matches ? 'small' : 'medium'}
                page={page}
                onChange={(_, p) => changePage(p)}
                count={lastPage}
                variant='outlined'
                shape='rounded'
              />
            </Box>
          )}
        </div>

        {transactionList.map((i, idx) => (
          <Box
            sx={{ my: 2 }}
            key={idx}
          >
            <ClientPaymentTransactionsTableTop
              status={i.productsForRent.map((rent) => rent.product.status)}
              name={i.productsForRent.map((rent) => rent.product.name)}
              price={i.productsForRent.map((rent) => rent.product.price)}
            />

            <TableContainer
              sx={{ my: 1.5 }}
              component={Paper}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {TableHeadings.map((item, index) => (
                      <StyledTableCell
                        align='center'
                        key={index}
                      >
                        {item}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ whiteSpace: 'nowrap' }}>
                  {i.paymentTransactions.map(
                    ({
                      id,
                      actualPaymentDate,
                      createdAt,
                      sum,
                      rentalStatusText,
                      paymentFrom,
                      paymentBy,
                      comment,
                      paymentStatus,
                      manager_confirmation_at,
                    }) => (
                      <TableRow
                        sx={{
                          backgroundColor:
                            STATUS_TRANSACTION_COLORS[paymentStatus],
                        }}
                      >
                        <CustomCell align='center'>{id}</CustomCell>
                        <CustomCell align='center'>
                          {dateFormat(actualPaymentDate) || '-'}
                        </CustomCell>
                        <CustomCell align='center'>{createdAt}</CustomCell>
                        <CustomCell align='center'>
                          {editBalance(sum)}
                        </CustomCell>
                        <CustomCell align='center'>
                          {rentalStatusText}
                        </CustomCell>
                        <CustomCell align='center'>
                          {dateFormat(paymentFrom) || '-'}
                        </CustomCell>
                        <CustomCell align='center'>
                          {dateFormat(paymentBy) || '-'}
                        </CustomCell>
                        <CustomCell align='center'>{comment || '-'}</CustomCell>
                        <CustomCell align='center'>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {paymentStatus !== 2 && (
                              <>
                                {(role === 'manager' &&
                                  manager_confirmation_at === null) ||
                                (role === 'admin' &&
                                  manager_confirmation_at === null) ? (
                                  <Tooltip title={'Подтвердить счет на оплату'}>
                                    <IconButton
                                      sx={{
                                        backgroundColor: '#fff',
                                        mr: 1,
                                        '&:hover': {
                                          backgroundColor: '#c2c2c2',
                                        },
                                      }}
                                      onClick={() => {
                                        setTransactionId(id);
                                        dispatch(
                                          setShowingDialog(
                                            'acceptPaymentInvoice',
                                          ),
                                        );
                                      }}
                                      color={'success'}
                                    >
                                      <CheckCircleOutlineIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}

                                <FormProvider {...methods}>
                                  <ClientPaymentTransactionsModal
                                    dataInvoice={{ comment, sum }}
                                    setTransactionId={setTransactionId}
                                    onSubmit={handleEditCommentPaymentInvoice}
                                  >
                                    {(role === 'manager' &&
                                      manager_confirmation_at === null) ||
                                    (role === 'admin' &&
                                      manager_confirmation_at === null) ? (
                                      <Tooltip title={'Изменить платеж'}>
                                        <IconButton
                                          sx={{
                                            backgroundColor: '#fff',
                                            mr: 1,
                                            '&:hover': {
                                              backgroundColor: '#c2c2c2',
                                            },
                                          }}
                                          onClick={() => setTransactionId(id)}
                                          color={'primary'}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                  </ClientPaymentTransactionsModal>
                                </FormProvider>
                              </>
                            )}

                            {(role === 'manager' &&
                              manager_confirmation_at === null) ||
                            role === 'admin' ? (
                              <Tooltip title='Удалить счёт на оплату'>
                                <IconButton
                                  sx={{
                                    backgroundColor: '#fff',
                                    mr: 1,
                                    '&:hover': { backgroundColor: '#c2c2c2' },
                                  }}
                                  color='warning'
                                  onClick={() => {
                                    setTransactionId(id);
                                    dispatch(
                                      setShowingDialog('deletePaymentInvoice'),
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <></>
                            )}

                            {role === 'manager' || role === 'admin' ? (
                              <Tooltip title='Отправить счёт на почту'>
                                <IconButton
                                  sx={{
                                    backgroundColor: '#fff',
                                    mr: 1,
                                    '&:hover': { backgroundColor: '#c2c2c2' },
                                  }}
                                  onClick={() => {
                                    setTransactionId(id);
                                    dispatch(
                                      setShowingDialog('sendMailInvoice'),
                                    );
                                  }}
                                  color='secondary'
                                >
                                  <AlternateEmailIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <></>
                            )}

                            {role === 'manager' || role === 'admin' ? (
                              <Tooltip title='Скачать счёт на оплату'>
                                <DownloadInvoiceModal transactionId={id}>
                                  <IconButton
                                    sx={{
                                      backgroundColor: '#fff',
                                      mr: 1,
                                      '&:hover': { backgroundColor: '#c2c2c2' },
                                    }}
                                    color='primary'
                                  >
                                    <SaveAltIcon />
                                  </IconButton>
                                </DownloadInvoiceModal>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </CustomCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <ClientPaymentTransactionsTableFooter
              balanceClient={editBalance(i.balanceClient)}
              exhibited={editBalance(i.exhibited)}
              received={editBalance(i.received)}
            />

            <Divider sx={{ my: 1 }} />
          </Box>
        ))}

        <ConfirmDialog
          title={'Отправить счет на почту?'}
          onHide={() => setTransactionId(null)}
          buttonTitle={'Отправить'}
          event={handleSendMailPaymentInvoice}
          name={'sendMailInvoice'}
          colorBtn='success'
        />

        <ConfirmDialog
          title={'Подтвердить счёт?'}
          onHide={() => setTransactionId(null)}
          buttonTitle={'Подтвердить счёт'}
          event={handleAcceptPaymentInvoice}
          name={'acceptPaymentInvoice'}
          colorBtn='success'
        />

        <ConfirmDialog
          title={'Удалить счёт на оплату?'}
          onHide={() => setTransactionId(null)}
          buttonTitle={'Удалить'}
          event={handleDeletePaymentInvoice}
          name={'deletePaymentInvoice'}
        />
      </Card>
    </>
  );
};
