import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { apiGet, apiPost } from '../../../../api';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardHeader,
  CircularProgress,
  DialogTitle,
  Drawer,
  useMediaQuery,
} from '@mui/material';
import errorHandler from '../../../layouts/errorHandler';
import Card from '@mui/material/Card';
import { setDialogEventDescription } from '../../../../store/events';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import ConfirmDialog from '../../../common/dialogs/confirm';
import { setShowingDialog } from '../../../../store/app';
import { DeleteForever, AddLocation } from '@mui/icons-material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Map } from './Map';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import docLogo from '../../../../assets/document/doc-logo.jpg';
import { CustomInput } from '../../../common/formControllers/CustomInput';
import { FormProvider, useForm } from 'react-hook-form';

const Input = styled('input')({
  display: 'none',
});

const TaskPage = () => {
  const dispatch = useDispatch();

  const [lat, setLat] = useState(55.799292);
  const [lng, setLng] = useState(49.105885);
  const [status, setStatus] = useState(null);

  const [position, setPosition] = useState({
    lat: lat,
    lng: lng,
  });
  const fileRef = useRef();

  const [statusId, setStatusId] = useState('');
  const [eventListExecutor, setEventListExecutor] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [eventDrawer, setEventDrawer] = useState(false);
  const [nowDate, setNowDate] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [commentExecuter, setCommentExecuter] = useState('');
  const [eachEventData, setEachEventData] = useState([]);
  const [eachEventFileData, setEachEventFileData] = useState(null);
  const [urlSelectedFile, setUrlSelectedFile] = useState(null);
  const [modalMap, setModalMap] = useState(false);
  const [loadTask, setLoadTask] = useState(false);
  const [loadSave, setLoadSave] = useState(false);
  const [isFetchMenu, setIsFetchMenu] = useState(false);
  const [showModalDeletePhoto, setShowModalDeletePhoto] = useState(false);
  const [photoId, setPhotoId] = useState('');
  const [taskByPhotoId, setTaskByPhotoId] = useState('');
  // Текст ошибки если фото или координаты не загружены
  const [error, setError] = useState(null);

  const modalMeda = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  const closeDrawer = () => {
    setEventDrawer(false);
    setCoordinates(null);
    setError(null);
    setUrlSelectedFile(null);
  };

  const getTaskExTaskList = () => {
    setLoadTask(true);
    apiGet({
      url: `/task-executor/task-list`,
    }).then((res) => {
      if (res.error) {
        setEventListExecutor([]);
      } else {
        setEventListExecutor(res.data);
      }
      setLoadTask(false);
    });
  };
  const getTaskInfo = (id) => {
    setTaskByPhotoId(id);
    setIsFetchMenu(true);
    apiGet({
      url: `/task/view?id=${id}`,
    }).then((res) => {
      setEachEventData(res.data);
      setEachEventFileData(res.data.files);
      setIsFetchMenu(false);
    });
  };

  const checkYourCoordinates = () => {
    setModalMap(true);
  };

  const changeStatusExecuter = () => {
    let data = {};
    data.status = statusId;

    apiPost({
      url: `/task-executor/update?id=${eachEventData.id}`,
      postData: data,
    })
      .then((res) => {
        if (res.error) {
          setError(res.data);
          return errorHandler(res.data);
        }
        toast.success('Задача обновлена');
        setEventDrawer(false);
        getTaskInfo();
        getTaskExTaskList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteFile = (i) => {
    const filterArray = selectedFile.filter((_, index) => index !== i);
    const filterUrlArray = urlSelectedFile.filter((_, index) => index !== i);
    setSelectedFile(filterArray);
    setUrlSelectedFile(filterUrlArray);

    fileRef.current.value = '';
  };

  const loadFile = (e) => {
    setSelectedFile((prev) => [...prev, ...e.target.files]);

    Promise.all(
      [...selectedFile, ...e.target.files].map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        });
      }),
    ).then(
      (images) => {
        /* Как только все промисы будут разрешены, обновите состояние с помощью массива url изображения. */
        setUrlSelectedFile(images);
      },
      (error) => {
        console.error(error);
      },
    );
  };

  useEffect(() => {
    setNowDate(new Date().toLocaleDateString());
    getTaskExTaskList();
  }, []);

  const openDrawer = () => {
    setEventDrawer(true);
  };

  const openFile = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleShowModalDeletePhoto = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setPhotoId(id);
    setShowModalDeletePhoto(true);
  };

  const submitDeletePhoto = () => {
    apiGet({
      url: `/task-executor/delete-document?id=${photoId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Успешно удалено');
      getTaskInfo(taskByPhotoId);
      handleCloseModalDeletePhoto();
    });
  };

  const handleCloseModalDeletePhoto = () => {
    setShowModalDeletePhoto(false);
  };

  const saveExecuteComment = () => {
    let data = {};
    data.comment = commentExecuter;
    data.coordinates = coordinates;

    if (selectedFile.length > 0) {
      let formData = new FormData();

      selectedFile.forEach((file) => {
        formData.append('files[]', file);
      });

      apiPost({
        url: `/task-executor/upload-document?id=${eachEventData.id}`,
        postData: formData,
      }).then((res) => {
        if (res.error) {
          return toast.error('Ошибка при загрузке документов');
        }
        toast.success('Фото загружены');
        setError(null);
        setUrlSelectedFile(null);
        getTaskInfo(taskByPhotoId);
      });
    }
    setSelectedFile([]);
    setLoadSave(true);
    // Save comment and coordinates
    apiPost({
      url: `/task-executor/update?id=${eachEventData.id}`,
      postData: data,
    }).then((res) => {
      if (res.error) {
        return toast.error('Ошибка обновления');
      }
      setError(null);
      toast.success('Данные сохранены');
      setLoadSave(false);
      // setCommentExecuter(res.data.comment);
      dispatch(setDialogEventDescription(false));
    });
  };

  const confirmDialogStatus = (id) => {
    setStatusId(id);
    dispatch(setShowingDialog('changeStatus'));
  };

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          setStatus('Unable to retrieve your location');
        },
      );
    }
  };

  useEffect(() => {
    const userRoleLocal = localStorage.getItem('userRole');

    if (userRoleLocal !== 'driver') {
      navigate('/404');
    }
  }, []);

  useEffect(() => {
    if (eachEventData && !Array.isArray(eachEventData)) {
      if (eachEventData?.coordinates) {
        setCoordinates(eachEventData?.coordinates);
      }

      if (eachEventData?.taskExecutor?.comment) {
        setCommentExecuter(eachEventData.taskExecutor.comment);
      }
    }
  }, [eachEventData]);

  return (
    <div>
      {loadTask ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
          }}
        >
          <CircularProgress color='primary' />
        </Box>
      ) : (
        <>
          {eventListExecutor?.length === 0 ? (
            <Typography
              sx={{ textAlign: 'center', fontSize: 26, fontWeight: 500 }}
            >
              Задач нет &#129300;
            </Typography>
          ) : (
            <>
              <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {eventListExecutor?.map((event, i) => (
                  <Card
                    raised
                    key={i}
                    sx={
                      nowDate !== event?.date
                        ? { my: 2, p: 2, background: '#f8f8f8' }
                        : { my: 2, p: 2, background: '#aefcdc' }
                    }
                  >
                    <CardHeader
                      title={event?.date}
                      titleTypographyProps={
                        nowDate !== event?.date
                          ? { variant: 'h5' }
                          : { variant: 'h5' }
                      }
                    />

                    {event?.tasks?.map((item) => (
                      <Button
                        key={item?.id}
                        sx={{ width: '100%' }}
                        onClick={() => {
                          openDrawer(item);
                          getLocation();
                          getTaskInfo(item.id);
                        }}
                      >
                        <Card sx={{ my: 2, display: 'flex', width: '100%' }}>
                          <CardHeader
                            sx={{ width: '100%' }}
                            title={
                              item?.taskProduct.length === 0
                                ? item?.nameText
                                : item?.nameText +
                                  ' Объект ' +
                                  item?.taskProduct.map((i) => i.product.name)
                            }
                            titleTypographyProps={{ variant: 'h6' }}
                            subheader={item?.comment || ''}
                            style={{ backgroundColor: item?.colorStatus }}
                          />
                        </Card>
                      </Button>
                    ))}
                  </Card>
                ))}
              </Box>

              <Drawer
                anchor={'left'}
                open={eventDrawer}
                onClose={closeDrawer}
              >
                <DialogContent>
                  <Box sx={{ width: 320, minHeight: 200, marginTop: '48px' }}>
                    {isFetchMenu ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    ) : (
                      <Card>
                        <div className='driver-menu-card driver-menu-header'>
                          <span>
                            {eachEventData?.nameText} {` : `}
                            {!!eachEventData?.taskProduct?.length ? (
                              <>
                                {eachEventData?.taskProduct?.map(
                                  ({ product }) => product?.name,
                                )}
                              </>
                            ) : (
                              <p>Нет бытовок</p>
                            )}
                          </span>
                          <ClearRoundedIcon
                            onClick={() => setEventDrawer(false)}
                          />
                        </div>

                        {!!eachEventData?.taskProduct?.length && (
                          <div className='driver-menu-card driver-menu-products'>
                            <span>Товары</span>
                            <div className='driver-menu-products-wrap'>
                              {eachEventData?.taskProduct?.map(
                                ({ product: { id, name } }) => (
                                  <div
                                    className='driver-menu-products-item'
                                    key={id}
                                  >
                                    {name}
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        )}

                        {eachEventData?.client && (
                          <>
                            <div className='driver-menu-card driver-menu-client'>
                              <span>Клиент</span>
                              <p>{eachEventData?.client?.name}</p>
                            </div>

                            <div className='driver-menu-card driver-menu-client'>
                              <span>
                                {eachEventData?.client?.phones.length > 1
                                  ? 'Номера телефонов '
                                  : 'Номер телефона'}
                              </span>
                              <div>
                                {eachEventData?.client?.phones.map(
                                  ({ phone, id }) => (
                                    <p key={id}>+{phone}</p>
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        <div className='driver-menu-card driver-menu-comment'>
                          <span>
                            {eachEventData?.comment
                              ? 'Комментарий'
                              : 'Комментариев нет'}
                          </span>
                          <p>{eachEventData?.comment || null}</p>
                        </div>

                        <div className='driver-menu-card driver-menu-input'>
                          <TextField
                            multiline
                            value={commentExecuter ?? ''}
                            onChange={(e) => setCommentExecuter(e.target.value)}
                            label={'Комментарий от исполнителя'}
                            name='comment'
                          />
                        </div>

                        <div className='driver-menu-card driver-menu-input'>
                          <div className='driver-menu-input-wrap'>
                            <TextField
                              onChange={(e) => setCoordinates(e.target.value)}
                              value={coordinates ?? ''}
                              placeholder={'Выберите координаты'}
                              label={'Ваши координаты'}
                              name='coordinates'
                            />
                            <IconButton
                              color='primary'
                              component='span'
                              onClick={checkYourCoordinates}
                            >
                              <AddLocation />
                            </IconButton>
                          </div>
                        </div>

                        <Box
                          className='driver-menu-card'
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          {!loadSave ? (
                            <Button
                              onClick={saveExecuteComment}
                              variant={'contained'}
                            >
                              Сохранить
                            </Button>
                          ) : (
                            <LoadingButton
                              loading
                              variant='contained'
                            >
                              Сохранить
                            </LoadingButton>
                          )}

                          <label
                            className='cameraButton'
                            htmlFor='icon-button-file'
                          >
                            <Input
                              accept='image/*;capture=camera'
                              defaultValue={''}
                              ref={fileRef}
                              id='icon-button-file'
                              type='file'
                              multiple
                              onChange={loadFile}
                            />
                            <IconButton
                              color='primary'
                              component='span'
                            >
                              <AddPhotoAlternateIcon />
                            </IconButton>
                          </label>
                        </Box>

                        {error === null ? (
                          <></>
                        ) : typeof error !== 'string' ? (
                          <></>
                        ) : (
                          <Typography
                            sx={{ color: '#d03333', fontSize: 15, p: 2 }}
                          >
                            {error}
                          </Typography>
                        )}

                        {!!urlSelectedFile?.length && (
                          <div className='driver-menu-upload'>
                            {urlSelectedFile.map((item, idx) => (
                              <div className='driver-menu-upload-wrap'>
                                <img
                                  src={
                                    item.indexOf('data:image') === 0
                                      ? item
                                      : docLogo
                                  }
                                  alt='Photo uploaded'
                                />
                                <ClearRoundedIcon
                                  onClick={() => deleteFile(idx)}
                                />
                              </div>
                            ))}
                          </div>
                        )}

                        <Accordion
                          defaultExpanded={!!eachEventFileData?.length}
                          sx={{ mt: 2 }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Документы к задаче</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {!!eachEventFileData?.length && (
                              <div className='driver-menu-files'>
                                {eachEventFileData?.map(
                                  ({ id, publicLink, typeText }) => (
                                    <div
                                      key={id}
                                      className='driver-menu-files-wrap'
                                    >
                                      <ClearRoundedIcon
                                        onClick={(e) =>
                                          handleShowModalDeletePhoto(e, id)
                                        }
                                      />
                                      <img
                                        onClick={() => openFile(publicLink)}
                                        src={
                                          typeText === 'Картинка'
                                            ? publicLink
                                            : docLogo
                                        }
                                        alt={'Документ'}
                                      />
                                    </div>
                                  ),
                                )}
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          defaultExpanded={
                            !!eachEventData?.previousFiles?.length
                          }
                          sx={{ mt: 2 }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>
                              Документы из предыдущей задачи
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {!!eachEventData?.previousFiles?.length && (
                              <div className='driver-menu-files'>
                                {eachEventData?.previousFiles?.map(
                                  ({ id, publicLink, typeText }) => (
                                    <div
                                      key={id}
                                      className='driver-menu-files-wrap'
                                    >
                                      <img
                                        onClick={() => openFile(publicLink)}
                                        src={
                                          typeText === 'Картинка'
                                            ? publicLink
                                            : docLogo
                                        }
                                        alt={'Документ'}
                                      />
                                    </div>
                                  ),
                                )}
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>

                        <div className='driver-menu-card driver-menu-comment'>
                          <span>
                            {eachEventData?.previousComment
                              ? 'Комментарий из предыдущей задачи:'
                              : 'Комментариев нет'}
                          </span>
                          <p>{eachEventData?.previousComment || null}</p>
                        </div>

                        <Box
                          sx={{ ml: 2 }}
                          className='driver-menu-card driver-menu-button-inWork'
                        >
                          {eachEventData?.status === 1 ? (
                            <Button
                              onClick={() => confirmDialogStatus(2)}
                              variant='outlined'
                              size='small'
                              style={{ backgroundColor: '#ffac00' }}
                            >
                              В работе
                            </Button>
                          ) : (
                            <div className='driver-menu-buttons'>
                              <Button
                                size='small'
                                onClick={() => confirmDialogStatus(4)}
                                variant='outlined'
                                style={{ backgroundColor: '#42a644' }}
                              >
                                Выполнено
                              </Button>
                              <Button
                                color={'abortButton'}
                                size='small'
                                onClick={() => confirmDialogStatus(3)}
                                variant='outlined'
                                style={{ backgroundColor: '#ff4d00' }}
                              >
                                Не выполнено
                              </Button>
                            </div>
                          )}
                        </Box>
                      </Card>
                    )}
                  </Box>
                </DialogContent>
              </Drawer>
              <ConfirmDialog
                name='changeStatus'
                event={changeStatusExecuter}
                title={'Изменить статус?'}
                buttonTitle={'Изменить'}
              />
              {/* <Drawer
                        sx={{ zIndex: 9999 }}
                        anchor={'top'}
                        open={modalMap}
                        onClose={() => setModalMap(false)}>
                        <Map position={position} setPosition={setPosition} setModalMap={setModalMap} setCoordinates={setCoordinates} />
                    </Drawer> */}

              <Dialog
                open={showModalDeletePhoto}
                sx={{ zIndex: 12000 }}
                onClose={handleCloseModalDeletePhoto}
              >
                <DialogTitle>Удалить Фото/документ?</DialogTitle>
                <DialogContent>
                  Удаленное фото/документ невозоможно будет восстановить
                </DialogContent>
                <DialogActions>
                  <Button
                    color={'abortButton'}
                    onClick={submitDeletePhoto}
                  >
                    Удалить
                  </Button>
                  <Button
                    onClick={handleCloseModalDeletePhoto}
                    autoFocus
                  >
                    Отменить
                  </Button>
                </DialogActions>
              </Dialog>

              <Modal
                open={modalMap}
                onClose={() => setModalMap(false)}
                aria-labelledby='parent-modal-title'
                aria-describedby='parent-modal-description'
                sx={{
                  zIndex: 9999,
                  overflow: 'auto',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    pt: 2,
                    px: 4,
                    pb: 3,
                  }}
                  style={modalMeda ? { width: 700 } : { width: '100%' }}
                >
                  <h2
                    style={{ marginTop: 0 }}
                    id='parent-modal-title'
                  >
                    Выберите ваше местоположение
                  </h2>
                  {/* <p id="parent-modal-description">
                                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                            </p> */}
                  <Map
                    position={position}
                    setPosition={setPosition}
                    setModalMap={setModalMap}
                    setCoordinates={setCoordinates}
                  />
                  <Button
                    sx={{ mt: 3, px: 3, py: 2 }}
                    onClick={() => setModalMap(false)}
                    variant={'contained'}
                    style={{ backgroundColor: '#42a644' }}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Modal>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TaskPage;
